"use client";

import * as React from "react";

import { useMediaQuery } from "@/hooks/use-media-query";
import type { Video_Flat } from "@/types/video";
import { VideoThumbnail } from "../video-thumb/video-thumb";

import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";

type CarouselProps = {
	videos: Video_Flat[];
	excludeVideoId?: number;
};

export function VideoThumbnailCarousel({
	videos,
	excludeVideoId,
}: CarouselProps) {
	const isXs = useMediaQuery("(max-width: 400px)");
	const isSm = useMediaQuery("(min-width: 401px) and (max-width: 600px)");
	const isMd = useMediaQuery("(min-width: 601px) and (max-width: 1023px)");
	const isLg = useMediaQuery("(min-width: 1024px)");

	const getSlidesToScroll = () => {
		if (isXs) return 1;
		if (isSm) return 1;
		if (isMd) return 2;
		if (isLg) return 3;
		return 1;
	};

	return (
		<Carousel
			className="flex-1 w-full h-full"
			opts={{
				align: "start",
				loop: false,
				dragFree: false,
				dragThreshold: 20,
				duration: 20,
				skipSnaps: true,
				slidesToScroll: getSlidesToScroll(),
			}}
		>
			<CarouselContent className="">
				{videos.map((video, index) => {
					if (video.id && video.id === excludeVideoId) {
						return null;
					}
					return (
						<CarouselItem
							key={`vth-${video.video_id}-${index}`}
							className="basis-[290px] sm:basis-[350px] mr-2"
						>
							<VideoThumbnail
								index={index}
								channel_id={video.channel_id}
								video_id={video.video_id}
								title={video.title}
								game_id={video.game_id}
								slug={video.slug}
							/>
						</CarouselItem>
					);
				})}
			</CarouselContent>
			<CarouselPrevious className="translate-x-14 -translate-y-14 w-10 h-10 hidden sm:flex z-10" />
			<CarouselNext className="-translate-x-14 -translate-y-14 w-10 h-10 hidden sm:flex z-10" />
			<div className="absolute bg-gradient-to-l from-background w-3 -right-1 top-0 h-full z-0" />
		</Carousel>
	);
}
