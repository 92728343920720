const normalizeString = (str: string): string => {
	const equivalents: Record<string, string> = {
		"&": "and",
		"’": "'",
		"–": "-", // En dash to hyphen
		"—": "-", // Em dash to hyphen
		",": "", // Remove commas
		".": "", // Remove periods
		" the ": " ", // Remove "the" (only if surrounded by spaces)
	};

	// Replace equivalents
	let normalized = str.toLowerCase().trim();
	for (const [key, value] of Object.entries(equivalents)) {
		normalized = normalized.replaceAll(key, value);
	}

	// Remove "the" if it's at the start or end of the string
	normalized = normalized.replace(/^the\s+|\s+the$/g, "");

	// Collapse multiple spaces into one
	normalized = normalized.replace(/\s+/g, " ");

	return normalized;
};

/**
 * Calculate confidence of match between two strings.
 * @param searchTerm The search term to compare.
 * @param target The target string to match against.
 * @returns A confidence score between 0 and 1.
 */
export const calculateMatchConfidence = (
	searchTerm: string,
	target: string,
): number => {
	// Normalize both strings
	const normalizedSearchTerm = normalizeString(searchTerm);
	const normalizedTarget = normalizeString(target);

	// Exact match
	if (normalizedSearchTerm === normalizedTarget) {
		return 1;
	}

	// Check for partial match based on inclusion
	if (normalizedTarget.includes(normalizedSearchTerm)) {
		const inclusionScore =
			normalizedSearchTerm.length / normalizedTarget.length;

		return inclusionScore > 0.5 ? inclusionScore : inclusionScore * 0.75;
	}

	// Use Levenshtein distance for fuzzy matching
	const levenshteinDistance = (a: string, b: string): number => {
		const dp: number[][] = Array.from({ length: a.length + 1 }, () =>
			Array(b.length + 1).fill(0),
		);

		for (let i = 0; i <= a.length; i++) dp[i][0] = i;
		for (let j = 0; j <= b.length; j++) dp[0][j] = j;

		for (let i = 1; i <= a.length; i++) {
			for (let j = 1; j <= b.length; j++) {
				const cost = a[i - 1] === b[j - 1] ? 0 : 1;
				dp[i][j] = Math.min(
					dp[i - 1][j] + 1, // Deletion
					dp[i][j - 1] + 1, // Insertion
					dp[i - 1][j - 1] + cost, // Substitution
				);
			}
		}
		return dp[a.length][b.length];
	};

	const distance = levenshteinDistance(normalizedSearchTerm, normalizedTarget);
	const maxLen = Math.max(normalizedSearchTerm.length, normalizedTarget.length);

	// Calculate confidence based on edit distance
	const similarityScore = 1 - distance / maxLen;

	return Math.max(0, similarityScore); // Ensure score is between 0 and 1
};
