"use client";

import { Button } from "@/components/ui/button";
import { Monitor, Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";

export function ThemeToggle() {
	const [mounted, setMounted] = useState(false);
	const { theme, setTheme } = useTheme();

	// Prevent hydration mismatch
	useEffect(() => setMounted(true), []);
	if (!mounted) return null;

	return (
		<div className="inline-flex items-center rounded-xl border border-border bg-background p-1 gap-1">
			<Button
				variant="ghost"
				size="icon"
				className={`h-8 w-8 px-0 ${theme === "system" ? "bg-muted" : ""}`}
				onClick={() => setTheme("system")}
			>
				<Monitor className="h-4 w-4" />
				<span className="sr-only">System theme</span>
			</Button>
			<Button
				variant="ghost"
				size="icon"
				className={`h-8 w-8 px-0 ${theme === "light" ? "bg-muted" : ""}`}
				onClick={() => setTheme("light")}
			>
				<Sun className="h-4 w-4" />
				<span className="sr-only">Light theme</span>
			</Button>
			<Button
				variant="ghost"
				size="icon"
				className={`h-8 w-8 px-0 ${theme === "dark" ? "bg-muted" : ""}`}
				onClick={() => setTheme("dark")}
			>
				<Moon className="h-4 w-4" />
				<span className="sr-only">Dark theme</span>
			</Button>
		</div>
	);
}
