import type { BggGameClean } from "@/data/bgg/bgg.model";
import { calculateMatchConfidence } from "@/lib/match-confidence";
import type { Channel } from "@/types/channel";
import type { Video_Flat } from "@/types/video";

export type ScoredSearchResult =
	| {
			type: "game";
			score: number;
			item: BggGameClean;
	  }
	| {
			type: "channel";
			score: number;
			item: Channel;
	  };

export const mergeAndSortGames = (
	games: BggGameClean[],
	videos: Video_Flat[],
): BggGameClean[] => {
	const gameMap: Map<string, BggGameClean> = new Map();

	// Initialize game map with empty videos array
	games.forEach((game) => {
		gameMap.set(game.id.toString(), { ...game, videos: [] });
	});

	// Map videos to their respective games
	videos.forEach((video) => {
		const game = gameMap.get(video.game_id.toString());
		if (game) {
			// @ts-ignore
			game.videos.push(video);
		}
	});

	// Separate boardgames and others
	const boardgames = Array.from(gameMap.values()).filter(
		(game) => game.type === "boardgame",
	);
	const otherGames = Array.from(gameMap.values()).filter(
		(game) => game.type !== "boardgame",
	);

	// Sort boardgames by rank
	boardgames.sort((a, b) => {
		const rankA =
			a.rank && a.rank !== "Not Ranked"
				? Number.parseInt(a.rank, 10)
				: Number.POSITIVE_INFINITY;
		const rankB =
			b.rank && b.rank !== "Not Ranked"
				? Number.parseInt(b.rank, 10)
				: Number.POSITIVE_INFINITY;
		return rankA - rankB;
	});

	// Combine and return
	return [...boardgames, ...otherGames];
};

export const getScoredItems = (
	searchTerm: string,
	channels: Channel[],
	games: BggGameClean[],
): ScoredSearchResult[] => {
	const scoredChannels: ScoredSearchResult[] = channels.map((channel) => ({
		type: "channel",
		score: calculateMatchConfidence(searchTerm, channel.attributes.name),
		item: channel,
	}));

	const scoredGames: ScoredSearchResult[] = games.map((game) => ({
		type: "game",
		score: calculateMatchConfidence(searchTerm, game.name),
		item: game,
	}));

	const allScored = [...scoredChannels, ...scoredGames];

	// Sort by score in descending order
	allScored.sort((a, b) => b.score - a.score);

	return allScored;
};
