import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const getRatingColour = (rating: number) => {
	let colour = "bg-slate-400";

	if (rating >= 8) {
		colour = "bg-emerald-500 hover:bg-emerald-600";
	} else if (rating >= 6) {
		colour = "bg-sky-500 hover:bg-sky-600";
	} else if (rating >= 4) {
		colour = "bg-orange-400 hover:bg-orange-500";
	} else if (rating >= 0) {
		colour = "bg-red-400 hover:bg-red-500";
	}
	return colour;
};

export const getWeightColour = (weight: number) => {
	if (weight < 3) {
		return "text-green-500";
	}

	return "text-orange-600";
};
