"use client";

import * as React from "react";

import { useMediaQuery } from "@/hooks/use-media-query";

import { Badge } from "@/components/ui/badge";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import { Skeleton } from "@/components/ui/skeleton";
import { getChannelsBySubscribers } from "@/data/strapi/channel-api";
import { slugifyString } from "@/lib/slug";
import type { Channel } from "@/types/channel";
import Link from "next/link";
import { useEffect, useState } from "react";
import {FallbackImage} from "@/components/fallback-image/fallback-image";

export function ChannelThumbnailCarousel() {
	const isXs = useMediaQuery("(max-width: 400px)");
	const isSm = useMediaQuery("(min-width: 401px) and (max-width: 600px)");
	const isMd = useMediaQuery("(min-width: 601px) and (max-width: 1023px)");
	const isLg = useMediaQuery("(min-width: 1024px)");

	const getSlidesToScroll = () => {
		if (isXs) return 1;
		if (isSm) return 2;
		if (isMd) return 3;
		if (isLg) return 4;
		return 1;
	};

	const [channelsLoading, setChannelsLoading] = useState<boolean>(true);
	const [channels, setChannels] = useState<Channel[]>([]);

	useEffect(() => {
		const fetchChannels = async (): Promise<Channel[]> => {
			return await getChannelsBySubscribers().then((channels) => {
				setChannels(channels);
				setChannelsLoading(false);
				return channels;
			});
		};
		fetchChannels();
	}, []);

	return (
		<div className="flex flex-col">
			{!channelsLoading && channels.length > 0 ? (
				<Carousel
					className="flex-1 w-full h-full pb-1"
					opts={{
						align: "start",
						loop: false,
						dragFree: false,
						dragThreshold: 20,
						duration: 20,
						skipSnaps: true,
						slidesToScroll: getSlidesToScroll(),
					}}
				>
					<CarouselContent className="">
						{channels.map((channel, index) => (
							<CarouselItem
								key={`gth-${channel.id}-${index}`}
								className="basis-[160px] sm:basis-[160px] mr-2"
							>
								<Link
									href={`/channels/${slugifyString(channel.attributes.channel_id, channel.attributes.name)}`}
									className={`flex flex-col gap-2 w-[150px] h-[190px] cursor-pointer group motion-blur-in-sm motion-duration-300 motion-delay-[${(index + 1) * 200}ms]`}
								>
									<Badge
										variant="secondary"
										className="rounded-full p-0 overflow-hidden flex-shrink-0 w-[140px] h-[140px]"
									>
										<FallbackImage
											src={
												channel.attributes.image ??
												channel.attributes.thumbnail ??
												""
											}
											alt={channel.attributes.name}
											width={140}
											height={140}
											className="rounded-full"
										/>
									</Badge>
									<h3 className="font-semibold leading-5 tracking-tight line-clamp-2 group-hover:underline text-center">
										{channel.attributes.name}
									</h3>
								</Link>
							</CarouselItem>
						))}
					</CarouselContent>
					<CarouselPrevious className="translate-x-14 -translate-y-8 w-10 h-10 hidden sm:flex z-10" />
					<CarouselNext className="-translate-x-14 -translate-y-8 w-10 h-10 hidden sm:flex z-10" />
					<div className="absolute bg-gradient-to-l from-background w-3 -right-1 top-0 h-full z-0" />
				</Carousel>
			) : (
				<ChannelsLoadingSkeleton />
			)}
		</div>
	);
}

export const ChannelThumbSkeleton = () => (
	<div className="flex flex-col space-y-3 w-[160px] h-[190px] mr-2">
		<Skeleton className="w-[150px] h-[150px] rounded-full" />
		<div className="space-y-2 gap-1">
			<Skeleton className="h-3 w-full" />
		</div>
	</div>
);

const ChannelsLoadingSkeleton = () => (
	<div className="flex flex-row gap-2 overflow-hidden h-[194px]">
		<ChannelThumbSkeleton />
		<ChannelThumbSkeleton />
		<ChannelThumbSkeleton />
		<ChannelThumbSkeleton />
		<ChannelThumbSkeleton />
		<ChannelThumbSkeleton />
		<ChannelThumbSkeleton />
	</div>
);
