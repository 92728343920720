import { AspectRatio } from "@/components/ui/aspect-ratio";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import type { Video_Flat } from "@/types/video";
import Image from "next/image";
import Link from "next/link";
import React from "react";

interface VideoCarouselProps {
	videos: Video_Flat[];
}

export default function VideoCarousel({ videos }: VideoCarouselProps) {
	return (
		<Carousel
			className="mt-2 relative"
			opts={{
				align: "start",
				loop: false,
				dragFree: false,
				dragThreshold: 20,
				duration: 20,
				skipSnaps: true,
				slidesToScroll: 2,
			}}
		>
			<CarouselContent className="-ml-1.5">
				{videos.map((video) => (
					<CarouselItem key={video.video_id} className="basis-[47.5%] pl-2">
						<Link href={`/videos/${video.slug}`}>
							<AspectRatio ratio={16 / 9}>
								<Image
									unoptimized
									src={`https://img.youtube.com/vi/${video.video_id}/hqdefault.jpg`}
									alt={video.title}
									className="object-cover pointer-events-none select-none rounded-md group-hover:brightness-75 transition"
									loading="lazy"
									fill
									style={{ objectFit: "cover" }}
								/>
							</AspectRatio>
							<p className="text-xs mt-1 truncate">{video.title}</p>
						</Link>
					</CarouselItem>
				))}
			</CarouselContent>
			<CarouselPrevious className="translate-x-14 -translate-y-6 w-8 h-8 hidden sm:flex z-10" />
			<CarouselNext className="-translate-x-14 -translate-y-6 w-8 h-8 hidden sm:flex z-10" />
			<div className="absolute bg-gradient-to-l from-gray-100 dark:from-gray-900 w-3 -right-1 top-0 h-full z-0" />
		</Carousel>
	);
}
