import { slugifyString } from "@/lib/slug";
import type { Channel } from "@/types/channel";
import Image from "next/image";
import Link from "next/link";
import React from "react";

interface ChannelItemProps {
	channel: Channel;
}

export default function ChannelItem({ channel }: ChannelItemProps) {
	return (
		<div className="mb-3 pb-3 px-2 border-b border-gray-200 motion-preset-fade-sm">
			<Link
				className="flex p-1 max-h-[120px]"
				href={`/channels/${slugifyString(channel.attributes.channel_id, channel.attributes.name)}`}
			>
				{channel.attributes.thumbnail && (
					<div className="flex-shrink-0 mr-3 max-h-[90px] overflow-hidden rounded-sm">
						<Image
							src={
								channel.attributes.thumbnail ||
								"/placeholder.svg?height=50&width=50"
							}
							alt={channel.attributes.name}
							width={90}
							height={90}
							className="rounded-full"
							style={{ objectFit: "cover" }}
						/>
					</div>
				)}
				<div className="flex-grow h-full items-start justify-start">
					<h2 className="text-lg font-bold leading-5 line-clamp-2 mb-1">
						{channel.attributes.name}
					</h2>
					<p className="text-xs text-gray-500">
						{channel.attributes.video_count} Videos
					</p>
					<p className="text-xs text-gray-500 line-clamp-3">
						{channel.attributes.description} Videos
					</p>
				</div>
			</Link>
		</div>
	);
}
