import type React from "react";
import { useEffect, useState } from "react";

const SearchingDots: React.FC = () => {
	const [dots, setDots] = useState<string>("");

	useEffect(() => {
		const interval = setInterval(() => {
			setDots((prev) => (prev.length < 3 ? `${prev}.` : ""));
		}, 500); // Change dot every 500ms

		return () => clearInterval(interval);
	}, []);

	return (
		<span className="text-lg">
			Searching<span className="inline-block ml-0.5 w-4 text-left">{dots}</span>
		</span>
	);
};

export default SearchingDots;
