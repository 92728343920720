"use client";

import Image from "next/image";
import { useState } from "react";

interface FallbackImageProps {
    src: string;
    alt: string;
    className?: string;
    width?: number;
    height?: number;
    priority?: true
}

export function FallbackImage({
                                  src,
                                  alt,
                                  className,
                                  priority,
    width,
    height,
                              }: FallbackImageProps) {
    const [imgSrc, setImgSrc] = useState(src);

    const handleError = () => {
        setImgSrc('https://placehold.co/600x600/orange/white?text=?');
    };

    return (
        <Image
            src={imgSrc}
            alt={alt}
            className={className}
            onError={handleError}
            width={width}
            height={height}
            priority={priority}
        />
    );
}
