"use client";

import SearchingDots from "@/components/loaders/searching";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { searchGames } from "@/data/bgg/bgg";
import { searchChannelsByName } from "@/data/strapi/channel-api";
import { getTrendingSearches, postSearchLog } from "@/data/strapi/search-api";
import { searchVideosByTitle } from "@/data/strapi/videos-api";
import type { SearchLog } from "@/types/search";
import type { Video_Flat } from "@/types/video";
import { TrendingUp } from "lucide-react";
import type React from "react";
import { useEffect, useState } from "react";
import ChannelItem from "./channel-item";
import GameItem from "./game-item";
import {
	type ScoredSearchResult,
	getScoredItems,
	mergeAndSortGames,
} from "./search-util";

interface SearchOverlayProps {
	isMobileView: boolean;
	onClose: () => void;
}

const MIN_SEARCH_LENGTH = 2;

const SearchOverlay: React.FC<SearchOverlayProps> = ({
	isMobileView,
	onClose,
}) => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [searchResults, setSearchResults] = useState<ScoredSearchResult[]>([]);
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [isResultsOpen, setIsResultsOpen] = useState<boolean>(false);
	const [trendingSearches, setTrendingSearches] = useState<string[]>([]);
	const [selectedKeyword, setSelectedKeyword] = useState<string>("");

	useEffect(() => {
		getTrendingSearches().then((searchLogs: SearchLog[]) => {
			setTrendingSearches(searchLogs.map((s) => s.keyword));
		});
	}, []);

	useEffect(() => {
		if (!isMobileView) {
			return;
		}

		if (isResultsOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}

		return () => {
			document.body.style.overflow = "unset";
		};
	}, [isResultsOpen, isMobileView]);

	useEffect(() => {
		if (selectedKeyword.length >= MIN_SEARCH_LENGTH && !isSearching) {
			search(selectedKeyword);
		}
	}, [selectedKeyword]);

	useEffect(() => {
		if (searchTerm.length === 0 && !isSearching) {
			setSearchResults([]);
			setSelectedKeyword("");
			setIsResultsOpen(false);
		}
	}, [searchTerm]);

	const search = (term: string) => {
		if (term && term.length >= MIN_SEARCH_LENGTH) {
			setIsSearching(true);
			document.getElementById("navbar-search")?.blur();
			Promise.all([
				searchVideosByTitle(term),
				searchGames(term),
				searchChannelsByName(term),
			]).then((results) => {
				const videos = results[0].map(
					(video) => video.attributes as Video_Flat,
				);
				const games = results[1];
				const channels = results[2];

				const gamesWithVideos = mergeAndSortGames(games, videos);
				const searchResults = getScoredItems(
					searchTerm,
					channels,
					gamesWithVideos,
				);

				setSearchResults(searchResults);
				setIsSearching(false);
				setIsResultsOpen(true);
			});
		}
	};

	const handleSearchSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
		if (searchTerm.length >= MIN_SEARCH_LENGTH && !isSearching) {
			search(searchTerm);
		}
		e.preventDefault();
	};

	const renderSearchOverlay = () => {
		// search in progress
		if (isSearching) {
			return (
				<div className="flex w-full flex-1 justify-center items-center text-gray-400 text-sm mt-2 motion-preset-fade-sm">
					<SearchingDots />
				</div>
			);
		}

		if (!isResultsOpen) {
			// if we have trending keywords
			if (trendingSearches.length > 0) {
				return (
					<div className="flex flex-col w-full  flex-1 items-start  mt-2 motion-preset-fade-sm">
						<span className="px-4 text-gray-500 text-[15px] tracking-tight">
							Trending searches <TrendingUp className="inline w-4 h-4" />
						</span>
						<div className="w-full flex flex-row gap-2 flex-wrap px-4 pt-2 justify-start">
							{trendingSearches.map((keyword, index) => (
								<Badge
									onClick={() => {
										setSelectedKeyword(keyword);
										setSearchTerm(keyword);
									}}
									key={keyword}
									className="cursor-pointer border-1 bg-background rounded-md bg-opacity-90 py-1.5 font-md flex border-input hover:bg-muted-foreground"
								>
									<span className="text-[14px] truncate max-w-[150px] text-foreground  overflow-hidden">{keyword}</span>
								</Badge>
							))}
						</div>
					</div>
				);
			}
		}

		// not searching
		if (!isSearching && isResultsOpen) {
			// show search results
			if ((searchTerm || selectedKeyword) && searchResults.length > 0) {
				return (
					<div className="gap-1 overflow-x-auto pb-8 sm:pb-0 snap-x snap-mandatory">
						{searchResults.map((searchResult) => (
							<span
								key={`${searchResult.type}${searchResult.item.id}`}
								onClick={() => {
									handleItemClick(searchResult);
								}}
							>
								{searchResult.type === "game" ? (
									<GameItem game={searchResult.item} />
								) : (
									<ChannelItem
										channel={searchResult.item}
										key={searchResult.item.attributes.name + "channel"}
									/>
								)}
							</span>
						))}
						))x
					</div>
				);
			} else {
				return (
					<div className="flex w-full tracking-tight flex-1 justify-center items-center text-gray-400 text-md mt-2 motion-preset-fade-sm">
						No Results Found
					</div>
				);
			}
		}
	};

	const handleItemClick = (item: ScoredSearchResult) => {
		if (item.type === "game") {
			postSearchLog(item.item.name.toLowerCase());
		} else {
			postSearchLog(item.item.attributes.name.toLowerCase());
		}
		onClose();
	};

	return (
		<div className="w-full max-w-md h-[36px] overflow-y-auto">
			<form
				onSubmit={handleSearchSubmit}
				className="flex-grow flex-1 h-[36px] sm:pl-2 max-w-md w-full motion-preset-slide-down motion-duration-200 motion-ease-out"
			>
				<Input
					id="navbar-search"
					type="search"
					placeholder="Search for games, channels or videos"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="w-full h-[36px] -mt-[2px] text-[14px] max-w-md mr-1"
					autoComplete="off"
					autoFocus
					name="search"
					enterKeyHint="search"
				/>
			</form>

			<div className="fixed sm:absolute top-[43px] right-0 sm:left-auto sm:right-10 z-50 overflow-hidden container max-w-md w-full px-0 sm:px-2 sm:py-1 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 h-full sm:h-[600px] shadow-lg max-h-full sm:max-h-[60vh] rounded-b-xl border-gray-200 dark:border-gray-700 border-0 sm:border-1 sm:border-t-0">
				<div className="w-full h-full overflow-y-auto pt-3">
					{renderSearchOverlay()}
				</div>
			</div>
		</div>
	);
};

export default SearchOverlay;
