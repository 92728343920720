"use client";

import React, { useEffect, useRef, useState } from "react";

import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { getChannelThumbnail } from "@/data/strapi/channel-api";
import { useIsInViewport } from "@/hooks/use-is-in-viewport";
import { slugifyString } from "@/lib/slug";
import type { Video_Flat } from "@/types/video";
import Image from "next/image";
import Link from "next/link";

interface VideoThumbProps
	extends Omit<Video_Flat, "createdAt" | "updatedAt" | "id"> {
	index: number;
	bigThumb?: boolean;
}

export function VideoThumbnail({
	video_id,
	title = "",
	slug,
	channel_id,
	index,
	bigThumb = false,
}: VideoThumbProps) {
	const thumbRef = useRef(null);
	const isInViewport = useIsInViewport(thumbRef);
	const [channelThumb, setChannelThumb] = useState<string | undefined>();
	const [channelName, setChannelName] = useState<string | undefined>();

	const cleanTitle = title.replace(/[^\p{ASCII}]/gu, "");

	/**
	 * Lazy load the channel thumbnail.
	 */
	useEffect(() => {
		if (isInViewport && !channelThumb && channel_id) {
			getChannelThumbnail(channel_id).then((response) => {
				if (typeof response !== "boolean") {
					setChannelThumb(response.attributes.thumbnail);
					setChannelName(response.attributes.name);
				}
			});
		}
	}, [isInViewport, channelThumb, channel_id]);

	return (
		<div
			ref={thumbRef}
			className={`group overflow-hidden ${bigThumb ? "w-full h-auto" : "w-[290px] h-[240px] sm:w-[350px] sm:h-[275px]"}   motion-blur-in-sm motion-duration-500 motion-delay-[${(index + 1) * 200}ms]`}
		>
			<Link href={`/videos/${slug}`}>
				<AspectRatio ratio={16 / 9}>
					<Image
						unoptimized
						src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`}
						alt={title}
						className="object-cover pointer-events-none select-none rounded-md border-1 group-hover:brightness-75 transition cursor-pointer"
						loading="lazy"
						fill
						style={{ objectFit: "cover" }}
					/>
				</AspectRatio>
			</Link>
			<div
				className={`flex flex-col items-start py-2.5 px-2 ${bigThumb ? "h-[60px]" : "h-[90px]"} space-x-4 select-none`}
			>
				<Link href={`/videos/${slug}`}>
					<h3 className="font-semibold leading-5 tracking-tight line-clamp-2 group-hover:underline">
						{cleanTitle}
					</h3>
				</Link>
				{channel_id && channelName && channelThumb && (
					<Link
						className="flex gap-2 mt-1.5 !mx-0 motion-preset-fade-sm group-hover:no-underline"
						href={`/channels/${slugifyString(channel_id, channelName)}`}
					>
						<Badge
							variant="secondary"
							className="rounded-full p-0 overflow-hidden flex-shrink-0 w-5 h-5"
						>
							<Image
								src={channelThumb}
								alt={channelName}
								width={18}
								height={18}
								className="rounded-full"
							/>
						</Badge>
						<p className="text-xs mt-0.5 text-muted-foreground truncate line-clamp-1 overflow-hidden">
							{channelName}
						</p>
					</Link>
				)}
			</div>
		</div>
	);
}

export const VideoThumbSkeleton = () => {
	return (
		<div className="flex flex-col space-y-3">
			<Skeleton className="w-[290px] sm:w-[350px] h-[163px] sm:h-[197px] rounded-xl" />
			<div className="space-y-2 gap-1">
				<Skeleton className="h-3 w-[250px]" />
				<Skeleton className="h-1.5 w-[200px]" />
			</div>
		</div>
	);
};
