import { type RefObject, useEffect, useMemo, useState } from "react";

export function useIsInViewport(ref: RefObject<HTMLElement | null>) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(() => {
		if (typeof window !== "undefined") {
			return new IntersectionObserver(
				([entry]) => {
					setIsIntersecting(entry.isIntersecting);
				},
				{
					threshold: 0,
				},
			);
		}
		return null;
	}, []);

	useEffect(() => {
		if (observer && ref.current) {
			observer.observe(ref.current);

			return () => {
				observer.disconnect();
			};
		}
	}, [ref, observer]);

	return isIntersecting;
}
