import type { Video_Flat } from "@/types/video";

interface BggLink {
	_attributes: {
		type:
			| "boardgamecategory"
			| "boardgamemechanic"
			| "boardgamedesigner"
			| "boardgameartist";
		value: string;
	};
}

interface BggItemAttributes {
	id: string;
	type: "boardgame" | "boardgameexpansion" | string;
}

interface BggRank {
	_attributes: {
		bayesaverage: string;
		friendlyname: string;
		id: string;
		name: string;
		type: string;
		value: string;
	};
}

interface BggValueAttributes {
	value: string;
}

interface BggStatistics {
	ratings: {
		average: { _attributes: BggValueAttributes };
		averageweight: { _attributes: BggValueAttributes };
		owned: { _attributes: BggValueAttributes };
		ranks: { rank: BggRank[] | BggRank };
	};
}

interface BggResponseItem {
	_attributes: BggItemAttributes;
	name?:
		| Array<{ _attributes: BggValueAttributes }>
		| { _attributes: BggValueAttributes };
	thumbnail?: { _text: string };
	image?: { _text: string };
	description?: { _text: string };
	yearpublished?: { _attributes: BggValueAttributes };
	minplayers?: { _attributes: BggValueAttributes };
	maxplayers?: { _attributes: BggValueAttributes };
	minplaytime?: { _attributes: BggValueAttributes };
	maxplaytime?: { _attributes: BggValueAttributes };
	link?: BggLink[];
	statistics?: BggStatistics;
}

export interface BggSearchItem {
	_attributes: BggItemAttributes;
	name: {
		_attributes: {
			type: string;
			value: string;
		};
	};
	yearpublished: {
		_attributes: BggValueAttributes;
	};
}

export interface BggGameClean {
	id: number;
	type: string;
	thumbnail?: string;
	image?: string;
	name: string;
	yearpublished: number;
	description?: string;
	players?: string | number;
	playtime?: string | number;
	rating?: number;
	weight?: number;
	owned?: number;
	rank?: string;
	boardgamecategory?: string[];
	boardgamemechanic?: string[];
	boardgamedesigner?: string[];
	boardgameartist?: string[];
	videos?: Video_Flat[];
}

const _BggThingResponse = (item: BggResponseItem): BggGameClean => {
	const linkData = {
		boardgamecategory: [] as string[],
		boardgamemechanic: [] as string[],
		boardgamedesigner: [] as string[],
		boardgameartist: [] as string[],
	};

	// Use map to simplify and avoid manual push
	for (const link of item.link || []) {
		if (link?._attributes) {
			const { type, value } = link._attributes;
			if (type in linkData) {
				linkData[type as keyof typeof linkData].push(value);
			}
		}
	}

	// Safely handle description, ensuring it exists
	const description = (item.description?._text || "")
		.replace(/&#10;/g, "<br/>")
		.replace(/&rsquo;/g, "'")
		.replace(/&mdash;/g, "-");

	// Safely extract and convert player and playtime values
	const minPlayers = Number.parseInt(
		item.minplayers?._attributes.value || "0",
		10,
	);
	const maxPlayers = Number.parseInt(
		item.maxplayers?._attributes.value || "0",
		10,
	);
	const minPlaytime = Number.parseInt(
		item.minplaytime?._attributes.value || "0",
		10,
	);
	const maxPlaytime = Number.parseInt(
		item.maxplaytime?._attributes.value || "0",
		10,
	);

	const playtime =
		minPlaytime === maxPlaytime
			? maxPlaytime
			: `${minPlaytime} - ${maxPlaytime}`;
	const players =
		minPlayers === maxPlayers ? maxPlayers : `${minPlayers} - ${maxPlayers}`;

	// Safely parse ratings and weight, with fallback to 0 if parsing fails
	const rating =
		Math.round(
			Number.parseFloat(
				item.statistics?.ratings?.average?._attributes.value || "0",
			) * 10,
		) / 10;
	const weight =
		Math.round(
			Number.parseFloat(
				item.statistics?.ratings?.averageweight?._attributes.value || "0",
			) * 10,
		) / 10;
	const owned = Number.parseInt(
		item.statistics?.ratings?.owned?._attributes.value || "0",
		10,
	);

	const rank = item.statistics
		? getRankValueById(item.statistics, "1")
		: "Not Ranked";

	// Safely handle optional fields like thumbnail and image
	const baseData = {
		id: Number.parseInt(item._attributes.id, 10),
		type: item._attributes.type,
		thumbnail: item.thumbnail?._text || "",
		image: item.image?._text || "",
		name: Array.isArray(item.name)
			? item.name[0]?._attributes.value
			: item.name?._attributes.value || "Unknown",
		yearpublished: Number.parseInt(
			item.yearpublished?._attributes.value || "0",
			10,
		),
		description,
		players,
		playtime,
		rating,
		weight,
		owned,
		rank,
	};

	return { ...baseData, ...linkData };
};

export default function parseBGGResponse(item: unknown): BggGameClean | false {
	if (!isValidBggResponseItem(item)) {
		return false;
	}

	const { type } = item._attributes;

	switch (type) {
		case "boardgame":
		case "boardgameexpansion":
			return _BggThingResponse(item);
		default:
			return false;
	}
}

/**
 * Type guard to ensure item conforms to the BggResponseItem interface
 */
// biome-ignore lint:
function isValidBggResponseItem(item: any): item is BggResponseItem {
	return (
		typeof item === "object" &&
		item !== null &&
		"_attributes" in item &&
		typeof item._attributes.id === "string" &&
		typeof item._attributes.type === "string"
	);
}

function getRankValueById(statistics: BggStatistics, targetId: string): string {
	const ranks = statistics.ratings.ranks.rank;

	// Normalize to array
	const rankArray = Array.isArray(ranks) ? ranks : [ranks];

	// Find the rank with the matching id
	const targetRank = rankArray.find((rank) => rank._attributes.id === targetId);

	// Return the value if found
	return targetRank?._attributes.value || "Not Ranked";
}
