import { Badge } from "@/components/ui/badge";
import type { BggGameClean } from "@/data/bgg/bgg.model";
import { slugifyString } from "@/lib/slug";
import { getRatingColour } from "@/lib/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import VideoCarousel from "./video-carousel";

interface GameItemProps {
	game: BggGameClean;
}

export default function GameItem({ game }: GameItemProps) {
	return (
		<div className="mb-3 pb-3 px-2 border-b border-gray-200 motion-preset-fade-sm">
			<Link
				className="flex p-1 max-h-[90px]"
				href={`/games/${slugifyString(game.id, game.name)}`}
			>
				{game.thumbnail && (
					<div className="flex-shrink-0 mr-3 max-h-[80px] overflow-hidden rounded-sm">
						<Image
							src={game.thumbnail || "/placeholder.svg?height=50&width=50"}
							alt={game.name}
							width={90}
							height={90}
							className="rounded-sm"
							style={{ objectFit: "cover" }}
						/>
					</div>
				)}
				<div className="flex-grow h-full items-start justify-start">
					<h2 className="text-lg font-bold leading-5 line-clamp-2 mb-1">
						{game.name}
					</h2>
					<p className="text-xs text-gray-500">{game.yearpublished}</p>
					{game.boardgamedesigner && (
						<p className="text-xs text-gray-500">
							{game.boardgamedesigner.toString()}
						</p>
					)}
				</div>

				<div className="flex flex-col justify-between items-end gap-2">
					{game.rating ? (
						<Badge
							className={`ml-2 tracking-tighter rounded-full w-9 h-9 flex items-center justify-center text-[18px] ${getRatingColour(game.rating)}`}
							variant="secondary"
						>
							{game.rating.toFixed(1)}
						</Badge>
					) : null}
					{game.type === "boardgameexpansion" ? (
						<span className="px-2 py-0.5 text-[12px] md:text-sm bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 rounded-full">
							Expansion
						</span>
					) : null}
				</div>
			</Link>
			{game.videos && game.videos.length > 0 && (
				<VideoCarousel videos={game.videos.slice(0, 15)} />
			)}
		</div>
	);
}
