"use client";

import SearchOverlay from "@/components/search/search-overlay";
import { useMediaQuery } from "@/hooks/use-media-query";
import { Dices, Rows3, Search, X, Youtube } from "lucide-react";
import { useTheme } from "next-themes";
import Image from "next/image";
import Link from "next/link";
import type React from "react";
import { useEffect, useState } from "react";
import {usePathname} from "next/navigation";


const smallLogo =
	"https://res.cloudinary.com/ddtcn4crx/image/upload/v1666631230/board-game-video-logo_p6dt6z.png";
const darkLogo =
	"https://res.cloudinary.com/ddtcn4crx/image/upload/v1669568030/boardgame-video-logo_uczre2.svg";
const lightLogo =
	"https://res.cloudinary.com/ddtcn4crx/image/upload/v1669568030/board-game-video-logo_rwnu0u.svg";

const Navbar = () => {
	const { resolvedTheme } = useTheme();
	const pathname = usePathname();
	const [logoSrc, setLogoSrc] = useState<string>(lightLogo);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const isMobileView = useMediaQuery("(max-width: 500px)"); // search overlay max-width

	useEffect(() => {
		setLogoSrc(resolvedTheme === "dark" ? darkLogo : lightLogo);
	}, [resolvedTheme]);

	const toggleSearch = () => {
		setIsSearchOpen(!isSearchOpen);
	};

	useEffect(() => {
		setIsSearchOpen(false);
	}, [pathname]);

	return (
		<nav
			aria-label="Main Navigation"
			className={`navbar h-[44px] bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 sticky top-0 z-20 w-full ${isSearchOpen ? "border-gray-100 dark:border-gray-900" : ""} border-b sm:border-gray-200 sm:dark:border-gray-700`}
		>
			<div className="container p-1.5 mx-auto max-w-6xl flex justify-between items-start w-full relative">
				<header
					className={`flex ml-0.5 ${isSearchOpen ? "w-10" : "w-auto"} sm:w-auto`}
				>
					<Link href="/" legacyBehavior>
						<Image
							className="h-8 w-auto mr-2 cursor-pointer"
							src={isSearchOpen && isMobileView ? smallLogo : logoSrc}
							alt="Board Game Videos Logo"
							width={132}
							height={32}
						/>
					</Link>
				</header>
				<div className="flex items-center pt-0 border-0 flex-grow flex-1 justify-end">
					{!isSearchOpen && (
						<ul className="flex items-center space-x-4 gap-2 mr-4 motion-preset-fade-sm">
							<li>
								<Link
									href="/feed"
									className="hover:text-gray-600 dark:hover:text-gray-300 flex flex-row"
								>
									<span className="hidden sm:flex mr-2">Feed</span>
									<Rows3
										width={25}
										height={25}
										className="motion-preset-fade-sm"
									/>
								</Link>
							</li>
							<li>
								<Link
									href="/videos"
									className="hover:text-gray-600 dark:hover:text-gray-300 flex flex-row"
								>
									<span className="hidden sm:flex mr-2">Videos</span>
									<Youtube
										width={25}
										height={25}
										className="motion-preset-fade-sm"
									/>
								</Link>
							</li>
							<li>
								<Link
									href="/games"
									className="hover:text-gray-600 dark:hover:text-gray-300 flex flex-row"
								>
									<span className="hidden sm:flex mr-2">Games</span>
									<Dices
										width={25}
										height={25}
										className="motion-preset-fade-sm"
									/>
								</Link>
							</li>
						</ul>
					)}
					{isSearchOpen && (
						<SearchOverlay isMobileView={isMobileView} onClose={toggleSearch} />
					)}
					<button
						type="button"
						onClick={toggleSearch}
						aria-label={isSearchOpen ? "Close search" : "Open search"}
						className="flex items-center justify-center w-8 h-8 px-1 ml-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
					>
						{isSearchOpen ? (
							<X width={25} height={25} className="motion-preset-fade-sm" />
						) : (
							<Search
								id="nav-search-open"
								width={25}
								height={25}
								className="motion-preset-fade-sm"
							/>
						)}
					</button>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
