import type { SearchLog } from "@/types/search";
import { strapiInstance } from "./strapi.base";

/**
 * Get the trending searches
 * @returns SearchLog[]
 */
export const getTrendingSearches = async (): Promise<SearchLog[]> => {
	try {
		return await strapiInstance.get<SearchLog[]>(`/search-logs/trending`);
	} catch (error) {
		console.error(`Failed to fetch search trends: ${error}`);
		return [];
	}
};

/**
 * Log the search
 * @returns SearchLog
 */
export const postSearchLog = async (keyword: string): Promise<boolean> => {
	try {
		if (!keyword.trim()) return false;

		const sessionId = localStorage.getItem("session_id") || crypto.randomUUID();
		localStorage.setItem("session_id", sessionId);

		await strapiInstance.post("/search-logs", {
			data: {
				keyword,
				session_id: sessionId,
			},
		});
		return true;
	} catch (error) {
		console.error(`Failed to fetch search trends: ${error}`);
		return false;
	}
};
