import convert from "xml-js";
import bggParseResponse, {
	type BggGameClean,
	type BggSearchItem,
} from "./bgg.model";

const BGG_API_BASE_URL = "https://boardgamegeek.com/xmlapi2/";

const options = {
	ignoreComment: true,
	alwaysChildren: true,
	compact: true,
};

// Helper function to handle errors and check for HTTP status
const handleFetchError = async (response: Response) => {
	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(
			`HTTP error! Status: ${response.status}, Message: ${errorText}`,
		);
	}
	return response;
};

// Function to handle API requests with fetch
const fetchData = async (url: string) => {
	try {
		const response = await fetch(url, { signal: AbortSignal.timeout(4000) });
		await handleFetchError(response);
		const xmlText = await response.text();
		return convert.xml2js(xmlText, options);
	} catch (error) {
		console.error("API request failed: ", error);
		throw error; // Rethrow so calling function can handle it
	}
};

// biome-ignore lint:
const reduceGameIDs = (items: any) => {
	let mappedIds: string[] = [];

	if (Object.keys(items).includes("_attributes")) {
		// Single value found
		mappedIds.push(items._attributes.id);
	} else {
		// biome-ignore lint:
		mappedIds = items.map((game: any) => game._attributes.id).splice(0, 15);
	}

	return [...new Set(mappedIds)].join(",");
};

const parseMultipleGamesResult = (items: BggGameClean[]) => {
	const parsed = items
		// biome-ignore lint:
		.map((game: any) => {
			return bggParseResponse(game);
		})
		// biome-ignore lint:
		.filter((g: any) => g !== false);

	// biome-ignore lint:
	return parsed.sort((a: any, b: any) =>
		b.owned > a.owned ? 1 : a.owned > b.owned ? -1 : 0,
	) as BggGameClean[];
};

export const getGame = async (gameId: number) => {
	try {
		const data = await fetchData(
			`${BGG_API_BASE_URL}thing?id=${gameId}&stats=1`,
		);

		if (data && "items" in data && data.items?.item) {
			return bggParseResponse(data.items.item);
		}
		return false; // Not found
	} catch (e) {
		console.error("bgg/getGame failed: ", e);
		return false; // In case of error, return empty array
	}
};

export const getGames = async (gameIds: string) => {
	try {
		const data = await fetchData(
			`${BGG_API_BASE_URL}thing?id=${gameIds}&stats=1`,
		);

		if (data && "items" in data && data.items?.item) {
			return parseMultipleGamesResult(data.items.item);
		}
		return []; // Not found
	} catch (e) {
		console.error("bgg/getGames failed: ", e);
		return []; // In case of error, return empty array
	}
};

export const getGameImages = async (gameId: number) => {
	try {
		const data = await fetchData(
			`https://api.geekdo.com/api/images?ajax=1&date=alltime&gallery=all&licensefilter=commercial&nosession=1&objectid=${gameId}&objecttype=thing&pageid=1&showcount=10&sort=top`,
		);

		if (data && "items" in data && data.items?.item) {
			return data.items.item.images.map(
				(image: any) => image.imageurl_lg,
			) as string[];
		}
		return []; // Not found
	} catch (e) {
		console.error("bgg/getGames failed: ", e);
		return []; // In case of error, return empty array
	}
};

export const searchGames = async (
	searchTerm: string,
): Promise<BggGameClean[]> => {
	try {
		const cleanSearchTerm = searchTerm.replaceAll(" ", "+");
		const data = await fetchData(
			`${BGG_API_BASE_URL}search?query=${cleanSearchTerm}`,
		);

		let games: BggGameClean[] = [];

		if (data && "items" in data && data.items?.item) {
			const { item } = data.items;
			const gameIds = reduceGameIDs(data.items.item);
			games = await getGames(gameIds);

			if (games.length < 1) {
				// failed to get games details... return simple game object
				games = data.items?.item.splice(0, 15).map((item: BggSearchItem) => ({
					name: item.name._attributes.value,
					yearpublished: item.yearpublished._attributes.value,
					id: item._attributes.id,
					type: item._attributes.type,
				})) as BggGameClean[];
			}
		}

		return games;
	} catch (e) {
		console.error("bgg/searchGames failed: ", e);
		return []; // In case of error, return empty array
	}
};

export const getHotGames = async () => {
	try {
		const response = await fetch(
			"https://api.geekdo.com/api/hotness?geeksite=boardgame&objecttype=thing",
		);
		const { items = [] } = await handleFetchError(response).then((res) =>
			res.json(),
		);

		// biome-ignore lint:
		const hotGamesCleaned = items.map((g: any) => ({
			id: g.objectid,
			name: g.name,
		}));

		// biome-ignore lint:
		return hotGamesCleaned.map((g: any) => g.id);
	} catch (error) {
		console.error("bgg/getHotGames failed: ", error);
		return []; // In case of error, return empty array
	}
};
