"use client";

import React from "react";

import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Skeleton } from "@/components/ui/skeleton";
import { slugifyString } from "@/lib/slug";
import type { CarouselGameType, Game } from "@/types/game";
import {
	ChevronDown,
	ChevronUp,
	ChevronsDown,
	ChevronsUp,
	Minus,
} from "lucide-react";
import Image from "next/image";
import Link from "next/link";

interface GameThumbProps {
	game: Game;
	onClickHandler: (game: Game) => void;
	index: number;
	carouselType: CarouselGameType;
	isActive: boolean;
}

export function GameThumbnail({
	game,
	onClickHandler,
	index,
	carouselType,
	isActive,
}: GameThumbProps) {
	const { imgSquare, name, backers = "", delta = 0 } = game;

	const showBackers = carouselType === "crowdfunding";

	const showIndex = ["trending"].includes(carouselType);

	const renderDelta = (delta: number) => {
		if (!["ownership", "played", "trending"].includes(carouselType))
			return null;
		let icon = (
			<Minus
				color="#ffffff"
				size={20}
				strokeWidth={2.5}
				style={{ opacity: 0.75 }}
			/>
		);
		if (delta === -1)
			icon = (
				<ChevronDown
					color="#e15151"
					size={22}
					strokeWidth={2.5}
					style={{ opacity: 0.96 }}
				/>
			);
		if (delta < -1)
			icon = (
				<ChevronsDown
					color="#e15151"
					size={22}
					strokeWidth={2.5}
					style={{ opacity: 0.96 }}
				/>
			);
		if (delta === 1)
			icon = (
				<ChevronUp
					color="#44c14c"
					size={22}
					strokeWidth={2.5}
					style={{ opacity: 0.96 }}
				/>
			);
		if (delta > 1)
			icon = (
				<ChevronsUp
					color="#44c14c"
					size={22}
					strokeWidth={2.5}
					style={{ opacity: 0.96 }}
				/>
			);

		return <div className="absolute bottom-1 right-1.5 z-10">{icon}</div>;
	};

	return (
		<div
			onClick={() => onClickHandler(game)}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClickHandler(game);
				}
			}}
			className={`w-[160px] h-[200px] sm:w-[190px] sm:h-[230px] cursor-pointer ${isActive ? "active" : ""} group motion-blur-in-sm motion-duration-300 motion-delay-[${(index + 1) * 200}ms]`}
		>
			<AspectRatio
				ratio={4 / 4}
				className="relative overflow-hidden rounded-md"
			>
				<Image
					unoptimized
					src={
						imgSquare
							? imgSquare
							: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
					}
					alt={name}
					className="group-[.active]:brightness-90 group-[.active]:scale-105 object-cover pointer-events-none select-none transition-all"
					loading="lazy"
					fill
					style={{ objectFit: "cover" }}
				/>
				<div className="absolute bg-gradient-to-t from-foreground dark:from-background w-full h-16 bottom-0 z-0 rounded-b-md group-[.active]:h-1/2 transition-all" />
				<div className="absolute bottom-2 left-1/2 -translate-x-1/2 w-2/3 rounded-md hidden group-[.active]:hidden transition-all flex-row justify-center items-center text-foreground font-semibold z-20 underline">
					<Link href={"#"}>Game Page</Link>
				</div>
				{renderDelta(delta)}
				{showIndex && (
					<div className="trending-index pointer-events-none absolute -bottom-3.5 left-1 z-10 bold text-[48px] opacity-80 group-[.active]:opacity-100 transition-all">
						{index + 1}
					</div>
				)}
				{showBackers && (
					<div className="absolute pointer-events-none bottom-0 right-3 text-[13px] mt-0.5">
						{backers} Backers
					</div>
				)}
			</AspectRatio>
			<div className="flex flex-col items-start py-1 px-0.5 h-[50px] space-x-4 select-none">
				<h3 className="text-xs font-semibold leading-4 tracking-tight line-clamp-2 group-[.active]:underline">
					{isActive ? (
						<Link href={`/games/${slugifyString(game.id, name)}`}>{name}</Link>
					) : (
						name
					)}
				</h3>
			</div>
		</div>
	);
}

export const GameThumbSkeleton = () => (
	<div className="flex flex-col space-y-3 w-[160px] h-[200px] sm:w-[190px] sm:h-[230px]">
		<Skeleton className="w-[160px] h-[160px] sm:w-[190px] sm:h-[190px] rounded-md" />
		<div className="space-y-2 gap-1">
			<Skeleton className="h-3 w-full" />
		</div>
	</div>
);
